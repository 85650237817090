<template>
  <div>
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Matrial Hisobot Bugungi Holati</h3>
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :items="materialreport"
              :items-per-page="materialreport.length"
              hide-default-footer
              class="elevation-1"
            ></v-data-table>
            <!-- <div class="text-center">
              <v-pagination
                v-model="page"
                :length="Math.ceil(materialreport.length / 10)"
              ></v-pagination>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Nomenklatura', value: 'import_category.name' },
        { text: 'Kirim Soni', value: 'remain_reports.import_amount' },
        { text: 'Kirim narxi', value: 'remain_reports.import_price' },
        { text: 'Kirim Summa', value: 'remain_reports.import_total' },
        { text: 'Chiqim Soni', value: 'remain_reports.export_amount' },
        { text: 'Chiqim Summa', value: 'remain_reports.export_total' },
        { text: 'Qoldiq Soni', value: 'remain_reports.report_amount' },
        { text: 'Qoldiq Summa', value: 'remain_reports.report_total' }
      ]
    }
  },
  created() {
    this.$store.dispatch('materialRepost')
  },
  computed: {
    materialreport() {
      return this.$store.state.accounts.materialrepost
    }
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  /* font-size: 1rem !important; */
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  /* font-size: 1rem !important; */
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
}
</style>
